import axios, { AxiosError } from 'axios'
import { AMBIENT_API_PROXY_PATH } from 'src/config-global'
import uuidv4 from 'src/utils/uuidv4'
import { getCredentials, refreshLoginSession } from 'src/auth/utils'
import { AddRemoveBotResponse } from '../graphql/types'

// for routes that require authentication, use this client
const authorizedApiClient = axios.create({
  baseURL: `${AMBIENT_API_PROXY_PATH}/api/v1`,
  timeout: 30000,
  withCredentials: true,
})

// for unauthenticated routes, use this client
const publicApiClient = axios.create({
  baseURL: `${AMBIENT_API_PROXY_PATH}/api/v1`,
  timeout: 30000,
  withCredentials: true,
})

authorizedApiClient.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const status = error.response ? error.response.status : null
    if (status === 401) {
      const { accessToken: token, refreshToken } = getCredentials()

      if (!token || !refreshToken) {
        return Promise.reject(error)
      }

      // Don't refresh directly, the auth-provider will handle this
      const refreshData = await refreshLoginSession()

      // update the original request and retry
      if (refreshData && error.config) {
        error.config.headers.Authorization = refreshData.accessToken
        return authorizedApiClient.request(error.config)
      }
    }

    // else return the original error
    return Promise.reject(error)
  }
)

authorizedApiClient.interceptors.request.use((config) => {
  const { accessToken } = getCredentials()
  if (config.headers) {
    config.headers.Authorization = accessToken ?? ''
  }
  if (!config.headers['Content-Type']) {
    config.headers.setContentType('application/json')
  }
  config.headers.setAccept('application/json')
  return config
})

export interface RefreshTokenResponse {
  token: string
  refreshToken: string
}

/**
 * Exchange a Google OAuth credential for a feed auth token
 * @param credential
 * @returns
 */
export const getFeedTokenForGoogleCredential = async (
  code: string,
  source?: string | undefined,
  sourceRef?: string | undefined,
  trialCode?: string | undefined
): Promise<RefreshTokenResponse> => {
  const { data }: { data: any } = await publicApiClient.post(
    '/auth/google',
    { code, source, source_ref: sourceRef, trial_code: trialCode },
    { headers: { 'Content-Type': 'application/json' } }
  )
  return {
    token: data.token,
    refreshToken: data.refresh_token,
  }
}

/**
 * Exchange a preauthorization code for a feed auth token
 * @param credential
 * @returns
 */
export const getTokenForPreauthorizationCode = async (
  code: string,
  nonce: string,
  sub: string,
  source?: string | undefined,
  sourceRef?: string | undefined,
  trialCode?: string | undefined
): Promise<RefreshTokenResponse> => {
  const { data }: { data: any } = await publicApiClient.post(
    '/auth/preauthorized',
    { code, nonce, sub, source, source_ref: sourceRef, trial_code: trialCode },
    { headers: { 'Content-Type': 'application/json' } }
  )
  return {
    token: data?.tokens?.token,
    refreshToken: data?.tokens?.refresh_token,
  }
}

/**
 * Refresh the ambient auth token. This should only be called by the auth provider
 * ! TODO: Remove the tokens when we finish migrating to the new cookie based auth
 * @param token
 * @param refreshToken
 * @returns
 */
export const refreshAmbientAuthToken = async (token: string, refreshToken: string): Promise<RefreshTokenResponse> => {
  // create a new client without interceptors because we don't want to intercept the refresh request
  const result = await publicApiClient.post(
    '/auth/refresh',
    { token, refresh_info: refreshToken },
    { headers: { 'Content-Type': 'application/json' } }
  )
  return {
    token: result.data.token as string,
    refreshToken: result.data.refresh_token as string,
  }
}

/**
 * Refresh the ambient auth token. This should only be called by the auth provider
 * ! TODO: Remove the tokens when we finish migrating to the new cookie based auth
 *
 * It doesn't return anything but will throw an error if the refresh fails
 */
export const refreshRootSession = async (): Promise<void> => {
  // create a new client without interceptors because we don't want to intercept the refresh request
  await publicApiClient.post(
    '/auth/refresh',
    { refresh_root_session: true },
    { headers: { 'Content-Type': 'application/json' } }
  )
}

/**
 * Request a sign in link be sent to the user's email
 * @param email
 */
export const requestEmailSignInLink = async (
  email: string,
  redirect_path?: string,
  source?: string | undefined,
  sourceRef?: string | undefined,
  trialCode?: string | undefined
) => {
  const nonce = uuidv4().replace(/-/g, '')
  // create a new client without interceptors
  await publicApiClient.post(
    '/auth/email',
    { email, nonce, redirect_path, source, source_ref: sourceRef, trial_code: trialCode },
    {
      headers: { 'Content-Type': 'application/json' },
    }
  )
}

/**
 * Add a bot to a meeting
 * @param meetingUrl the meeting url
 * @param meetingId the meeting id
 * @param role the role of the bot
 * @returns the bot response
 */
export const inviteBotToMeeting = async (
  meetingUrl: string,
  meetingId?: string,
  role?: 'host' | 'cohost' | 'attendee' | 'unknown'
) => {
  const { data } = await authorizedApiClient.post<AddRemoveBotResponse>(
    `/recallai/add_bot`,
    {
      meeting_url: meetingUrl,
      meeting_id: meetingId,
      role,
    },
    {
      baseURL: `${AMBIENT_API_PROXY_PATH}`,
    }
  )
  return data
}

/**
 * Remove a bot from a meeting
 * @param botId the bot id to remove
 * @returns the add bot response
 */
export const removeMeetingBot = async (botId: string) => {
  const { data } = await authorizedApiClient.post<AddRemoveBotResponse>(
    `/recallai/remove_bot`,
    {
      bot_id: botId,
    },
    {
      baseURL: `${AMBIENT_API_PROXY_PATH}`,
    }
  )
  return data
}

/**
 * Upload a profile image
 * @param file
 * @returns the response including the image url
 */
export async function uploadProfileImage(file: File) {
  // make sure the user is authenticated before uploading since this is a long running request
  await refreshLoginSession()
  const formData = new FormData()
  formData.append('photo', file)
  const { data } = await authorizedApiClient.post('/accounts/profileimage', formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
    // allow up to 30 seconds for the upload to complete
    timeout: 60000,
  })
  return data as { url: string }
}

/**
 * Upload a profile image
 * @param file
 * @returns the response including the image url
 */
export async function uploadAttachment(file: File, contextId: string, contextType: 'post' | 'project') {
  const formData = new FormData()
  formData.append('photo', file)
  formData.append('context_id', contextId)
  formData.append('context_type', contextType)
  const { data } = await authorizedApiClient.post('/files/upload/asset', formData, {
    baseURL: AMBIENT_API_PROXY_PATH,
    headers: {
      'content-type': 'multipart/form-data',
    },
    // allow up to 30 seconds for the upload to complete
    timeout: 60000,
  })
  return data as { url: string }
}
